






















import Vue from 'vue'
import Component from 'vue-class-component'

import ProgressBar from '@/components/generic/ProgressBar.vue'
import Header from '@/components/generic/Header.vue'
import CallToAction from '@/components/generic/buttons/CallToAction.vue'

const props = {
  isDateless: Boolean
}
const SuccessProps = Vue.extend({ props })

@Component({
  components: {
    ProgressBar,
    Header,
    CallToAction
  },
  props
})
export default class Success extends SuccessProps {
  onCallToActionClick() {
    window.location.href = 'https://www.rrs.nl/'
  }
}
